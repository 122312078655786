import React from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

const Notification = ({showNotification, setShowNotification, message, type}) => {


    if(showNotification){
        return(
            <ToastContainer
            className="p-3"
            position='bottom-end'
            style={{ zIndex: 1, position: 'fixed' }}
            >
                <Toast
                    className="d-inline-block m-1"
                    bg={type}
                    onClose={() => setShowNotification(false)} show={showNotification} delay={7000} autohide
                >
                    <Toast.Header>
                    <strong className="me-auto">{type === 'danger'? 'Error' : type.toUpperCase()}</strong>
                    </Toast.Header>
                    <Toast.Body className='Dark text-white'>
                    {message}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        )
    }else return null;
}

export default Notification;