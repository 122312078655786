import React, { useState } from 'react';
import { ListGroup, FormControl, Button } from 'react-bootstrap';

const CategoriesColumn = ({ categories, onSelectCategories, selectedCategories, setSelectedCategory, handleClearAllCategories }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredCategories = categories.filter(category => 
    category.node.translations.edges[0].node.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectAllCategories = () => {
    const allCategories = filteredCategories
            .map(cat => ({
                name: cat.node.translations.edges[0].node.name,
            }));
    
    setSelectedCategory(allCategories);
  }

  return (
    <div>
      <h5>Fabric Categories</h5>
      <FormControl 
        placeholder="Search categories..." 
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: '10px' }}
        value={searchTerm}
      />
      <Button variant="outline-dark" style={{ margin: '0 10px 10px 0' }} size="sm" onClick={handleSelectAllCategories}>Select all</Button>
      <Button variant="outline-dark" style={{ margin: '0 0 10px 10px' }} size="sm" onClick={handleClearAllCategories}>Clear all</Button>
      <ListGroup style={{ maxHeight: '500px', overflowY: 'scroll', border: '1px solid #888' }}>
        {filteredCategories.map((category, index) => (
          <ListGroup.Item 
            key={index} 
            action 
            onClick={() => onSelectCategories({name: category.node.translations.edges[0].node.name})}
            active={selectedCategories.some(item => item.name === category.node.translations.edges[0].node.name)}
          >
            {category?.node.translations.edges[0].node.name}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default CategoriesColumn;
