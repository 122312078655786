import React, { useContext } from 'react';
import { ScopeContext } from '../Context/ScopeContext';
import { Row, Col } from 'react-bootstrap';
import CategoriesColumn from './CategoriesColumn';
import FabricsColumn from './FabricsColumn';
import { useQuery, gql } from '@apollo/client';

const FABRICS_DATA = gql`
{
    fabricCategories {
      edges {
        node {
            active
          translations(lang: "en") {
            edges {
              node {
                name
              }
            }
          }
          fabrics {
            edges {
              node {
                reference
                translations(lang: "en") {
                  edges {
                    node {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
}
`;


const FabricsScope = () => {

    const { 
        selectedFabrics,
        setSelectedFabrics,
        selectedCategory, 
        setSelectedCategory
    } = useContext(ScopeContext);

    const { loading: loadingFabrics, error: errorFabrics, data: dataFabrics } = useQuery(FABRICS_DATA);

    if (loadingFabrics) return <div><img alt='Loading fabrics...' src='./loadingfabrics.gif' /><strong>Loading fabrics...</strong></div>;
    if (errorFabrics) return <p>Error loading fabrics :(</p>;

    const handleSelectCategory = (category) => {
        if(selectedCategory.some(item => item.name === category.name)){
            const filteredCategories = selectedCategory.filter(item => item.name !== category.name)
            setSelectedCategory(filteredCategories)
        }else{
            setSelectedCategory([
                ...selectedCategory,
                category
            ])
        }
    };
  

    // Handle fabrics selection
    const handleSelectFabrics = (fabric) => {
        if(selectedFabrics.some(item => item.ref === fabric.ref)){
            const filteredFabrics = selectedFabrics.filter(item => item.ref !== fabric.ref)
            setSelectedFabrics(filteredFabrics)
        }else{
            setSelectedFabrics([
                ...selectedFabrics,
                fabric
            ])
        }
    };

    function getFabricsBySelectedCategories() {
        
        if (!selectedCategory?.length) return [];
        const categoriesArray = dataFabrics?.fabricCategories?.edges?.filter(cat => cat.node.active === true)
        let fabricsArray = []

        for (let i = 0; i < categoriesArray.length; i++) {
            for(let j = 0; j < selectedCategory.length ; j++){
                if (categoriesArray[i].node.translations.edges[0].node.name === selectedCategory[j].name) {
                    fabricsArray = fabricsArray.concat(categoriesArray[i].node.fabrics.edges);
                }
            }
        }
        
        const uniqueFabrics = fabricsArray.filter((obj, index) => {
            return index === fabricsArray.findIndex(o => obj.node.reference === o.node.reference);
        });

        return uniqueFabrics;
    }

    const handleClearAllCategories = () => {
        setSelectedCategory([])
        setSelectedFabrics([])
    }
  

    return (
        <Row style={{ marginTop: '40px' }}>
            <Col md={3}>
            <CategoriesColumn 
                categories={dataFabrics?.fabricCategories?.edges?.filter(cat => cat.node.active === true) || null} 
                onSelectCategories={handleSelectCategory} 
                selectedCategories={selectedCategory} 
                handleClearAllCategories={handleClearAllCategories}
                setSelectedCategory={setSelectedCategory}
            />
            </Col>
            
            <Col md={4}> 
            <FabricsColumn 
                fabrics={selectedCategory.length ? getFabricsBySelectedCategories() : []} 
                onSelectFabric={handleSelectFabrics} 
                selectedFabrics={selectedFabrics}
                setSelectedFabrics={setSelectedFabrics}
            />
            </Col>

            <Col md={4}>
                <h5>Selected Fabrics</h5>
                <div className='selectedList'>
                {
                    selectedFabrics.length ? selectedFabrics.map(fabric => (
                        <div className='selectedProduct' key={fabric.name}>
                            {fabric.name}
                        </div>
                    )) : <>No Fabric selected</>
                }
                </div>
            </Col>
        </Row>
    );
};

export default FabricsScope;
