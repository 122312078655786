export const productData = [
  {
    Title: 'Drake 86" Sofa Slipcover',
    Vendor: "West Elm",
    "Variant SKU": "WE-DE-86",
  },
  {
    Title: 'Harmony 76" Sofa Slipcover',
    Vendor: "West Elm",
    "Variant SKU": "WE-HY-76",
  },
  {
    Title: 'Harmony 82" Sofa Slipcover',
    Vendor: "West Elm",
    "Variant SKU": "WE-HY-82",
  },
  {
    Title: 'Haven 84" Sofa Slipcover',
    Vendor: "West Elm",
    "Variant SKU": "WE-HN-84",
  },
  {
    Title: 'Henry 66" Sofa Slipcover',
    Vendor: "West Elm",
    "Variant SKU": "WE-HE-66",
  },
  {
    Title: 'Shelter 84" Sofa Slipcover',
    Vendor: "West Elm",
    "Variant SKU": "WE-SHR-84",
  },
  {
    Title: "Emory Sofa Slipcover",
    Vendor: "Arhaus",
    "Variant SKU": "AS-EMY-94",
  },
  {
    Title: "Nantucket Ottoman Slipcover",
    Vendor: "Rowe",
    "Variant SKU": "RE-NT-32F",
  },
  {
    Title: "Rowe Nantucket Loveseat Slipcover",
    Vendor: "Rowe",
    "Variant SKU": "RE-NT-63",
  },
  {
    Title: "Rowe Nantucket Sofa Slipcover",
    Vendor: "Rowe",
    "Variant SKU": "RE-NT-84",
  },
  {
    Title: "Axis II 1-Arm Chaise Slipcover",
    Vendor: "Crate and Barrel",
    "Variant SKU": "CB-AS-35M",
  },
  {
    Title: "Axis II 2-Seat Queen Sleeper Sofa Slipcover",
    Vendor: "Crate and Barrel",
    "Variant SKU": "CB-AS-88B",
  },
  {
    Title: 'Lounge II 83" Sofa Slipcover',
    Vendor: "Crate and Barrel",
    "Variant SKU": "CB-LE-83-STD",
  },
  {
    Title: 'Lounge II Petite 83" Slipcover',
    Vendor: "Crate and Barrel",
    "Variant SKU": "CB-LE-83-PTD",
  },
  {
    Title: "Petrie Midcentury Apartment Sofa Slipcover",
    Vendor: "Crate and Barrel",
    "Variant SKU": "CB-PE-76",
  },
  {
    Title: "Balthazar 3 Seater Sofa Bed Slipcover",
    Vendor: "Maisons Du Monde",
    "Variant SKU": "MDM-BZR-66B",
  },
  {
    Title: "Barcelone 3/4 Seater Sofa Bed Slipcover",
    Vendor: "Maisons Du Monde",
    "Variant SKU": "MDM-BE-80B",
  },
  {
    Title: "Barcelone 5 Seater Sofa Slipcover",
    Vendor: "Maisons Du Monde",
    "Variant SKU": "MDM-BE-98",
  },
  {
    Title: "Louvain 2 seater sofa bed slipcover",
    Vendor: "Maisons Du Monde",
    "Variant SKU": "MDM-LV-64B",
  },
  {
    Title: 'Bennett Roll Arm 86" Two Seat Sofa Slipcover',
    Vendor: "Ethan Allen",
    "Variant SKU": "EA-BR-86-2C",
  },
  {
    Title: 'Bennett Roll Arm 86" Three Seat Sofa Slipcover',
    Vendor: "Ethan Allen",
    "Variant SKU": "EA-BR-86-3C",
  },
  {
    Title: "Reiko Chair Unit",
    Vendor: "Habitat",
    "Variant SKU": "HT-RO-37X",
  },
  {
    Title: "1 Seater Ash Sofa Cover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-ASH-1",
  },
  {
    Title: "3 Seater Ash Sofa Cover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-ASH-3",
  },
  {
    Title: "2004 Compact Sofa Cover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-COM-2X-2004",
  },
  {
    Title: "2019/2020 Feather Pocket Coil Ottoman Cover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-FPC-0-2019",
  },
  {
    Title: "2019/2020 Wide Arm Feather Pocket Coil 2 Seater Slipcover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-FPC-2-2019",
  },
  {
    Title: "2.5 Seater Wide Arm Sofa Cover Feather Insert",
    Vendor: "MUJI",
    "Variant SKU": "MJ-FPC-3P-2010",
  },
  {
    Title: "2011 Wide Arm 2.5 Seater Down Feather Slipcover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-FPC-3P-2011",
  },
  {
    Title: "2015 Wide Arm 2.5 Seater Feather Pocket Coil Slipcover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-FPC-3P-2015",
  },
  {
    Title: "2019/2020 Wide Arm Feather Pocket Coil High Back Slipcover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-FPC-3P-2019",
  },
  {
    Title: "Headrest 2 Seater Cover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-HR-2",
  },
  {
    Title: "Living & Dining Room Cushion Chair Cover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-LD-CC",
  },
  {
    Title: "Leather 2P Sofa Cover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-LS-2B",
  },
  {
    Title: "Box Sofa Cover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-MBS-2",
  },
  {
    Title: "Sofa Bench Ottoman Cover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-MJS-0B",
  },
  {
    Title: "Sofa Bench Cover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-MJS-9",
  },
  {
    Title: "Sectional Sofa (2007) Cover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-MSS-3-2007",
  },
  {
    Title: "Wooden Sofa Bed Cover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-OTH-3BX",
  },
  {
    Title: "1 Seater Sofa Cover 2002",
    Vendor: "MUJI",
    "Variant SKU": "MJ-OWA-1-2002",
  },
  {
    Title: "2.5 Seater Sofa Cover 1996",
    Vendor: "MUJI",
    "Variant SKU": "MJ-OWA-3P-1996",
  },
  {
    Title: "2.5 Seater Wide Arm Sofa Cover 2007",
    Vendor: "MUJI",
    "Variant SKU": "MJ-OWA-3P-2007",
  },
  {
    Title: "1 Seater Slim Arm Sofa Cover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-SLM-1",
  },
  {
    Title: "2.5 Seater Slim Arm Sofa Cover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-SLM-3P",
  },
  {
    Title: "2019 Wide Arm Urethane Pocket Coil Ottoman Slipcover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-UPC-0-2019",
  },
  {
    Title: "Unit Sofa Single Cover Large",
    Vendor: "MUJI",
    "Variant SKU": "MJ-USL-1X",
  },
  {
    Title: "Unit Sofa Single Cover Small",
    Vendor: "MUJI",
    "Variant SKU": "MJ-USS-1X",
  },
  {
    Title: "PB Comfort Roll Arm Loveseat Slipcover",
    Vendor: "Pottery Barn",
    "Variant SKU": "PB-CRA-67",
  },
  {
    Title: "PB Comfort Square Arm Armchair Slipcover",
    Vendor: "Pottery Barn",
    "Variant SKU": "PB-CSQ-37",
  },
  {
    Title: "PB Comfort Square Arm Corner Seat Slipcover",
    Vendor: "Pottery Barn",
    "Variant SKU": "PB-CSQ-41X",
  },
  {
    Title: "PB Comfort Square Arm Armless Loveseat Slipcover",
    Vendor: "Pottery Barn",
    "Variant SKU": "PB-CSQ-59X",
  },
  {
    Title: "PB Pearce Roll Arm Wedge Seat Slipcover",
    Vendor: "Pottery Barn",
    "Variant SKU": "PB-PRA-48X",
  },
  {
    Title: "Pearce Roll Armless Chair Slipcover",
    Vendor: "Pottery Barn",
    "Variant SKU": "PB-PRA-41X",
  },
  {
    Title: "Pearce Roll Arm Left/Right Arm Chaise Slipcover",
    Vendor: "Pottery Barn",
    "Variant SKU": "PB-PRA-35M",
  },
  {
    Title: 'PB Charleston Loveseat 71"" Slipcover',
    Vendor: "Pottery Barn",
    "Variant SKU": "PB-CN-71",
  },
  {
    Title: "Pearce Roll Arm Armless Loveseat Slipcover",
    Vendor: "Pottery Barn",
    "Variant SKU": "PB-PRA-63X",
  },
  {
    Title: 'York Square Arm Deep Seat 95" Sofa Slipcover',
    Vendor: "Pottery Barn",
    "Variant SKU": "PB-YSAD-94-1C",
  },
  {
    Title: 'Pearce Roll Arm 72" Sofa Slipcover',
    Vendor: "Pottery Barn",
    "Variant SKU": "PB-PRA-73",
  },
  {
    Title: "PB Comfort Square Arm Left/Right Armchair Slipcover",
    Vendor: "Pottery Barn",
    "Variant SKU": "PB-CSQ-38M",
  },
  {
    Title: 'York Square Arm Bench Seat Sofa 80.5" Slipcover',
    Vendor: "Pottery Barn",
    "Variant SKU": "PB-YSA-81-1C",
  },
  {
    Title: "Angby 2 Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-AY-2",
  },
  {
    Title: "Brathult Corner Sofabed Covers",
    Vendor: "IKEA",
    "Variant SKU": "IK-BHT-25B",
  },
  {
    Title: "Backsalen 1.5 seat Armchair Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-BSN-1",
  },
  {
    Title: "Backsalen 2-seat Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-BSN-2",
  },
  {
    Title: "Ekolsund Recliner Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-ED-1",
  },
  {
    Title: "Ektorp 2 Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-EP-2",
  },
  {
    Title: "Ektorp Chaise Lounge Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-EP-5M",
  },
  {
    Title: "Ektorp Chaise Lounge No Arms Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-EP-5X",
  },
  {
    Title: "Flottebo Sofa Bed Cover (90cm)",
    Vendor: "IKEA",
    "Variant SKU": "IK-FLO-3BX-2C",
  },
  {
    Title: "Finnala Footstool Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-FNA-0",
  },
  {
    Title: "Finnala 1 Seat Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-FNA-1X",
  },
  {
    Title: "Finnala 2 Seat Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-FNA-2",
  },
  {
    Title: "Finnala Sofa with Chaise Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-FNA-25",
  },
  {
    Title: "Finnala Loveseat Sleeper Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-FNA-2BX",
  },
  {
    Title: "Finnala 2 Seat Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-FNA-2X",
  },
  {
    Title: "Finnala Chaise Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-FNA-5X",
  },
  {
    Title: "Finnala Corner Seat Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-FNA-6",
  },
  {
    Title: "Finnala Armrest Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-FNA-8",
  },
  {
    Title: "Finnala Headrest Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-FNA-HRX",
  },
  {
    Title: "Fagelbo Chaise Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-FO-25B",
  },
  {
    Title: "Farlov 2 Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-FV-2",
  },
  {
    Title: "Farlov 3 Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-FV-3",
  },
  {
    Title: "Gronlid Footstool Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-GRD-0",
  },
  {
    Title: "Gronlid Footstool with Storage Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-GRD-0S",
  },
  {
    Title: "Gronlid Armchair Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-GRD-1",
  },
  {
    Title: "Gronlid One-Seat Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-GRD-1X",
  },
  {
    Title: "Gronlid Loveseat or 2-seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-GRD-2",
  },
  {
    Title: "Gronlid Sofa with Chaise Lounge Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-GRD-25",
  },
  {
    Title: "Gronlid Two-Seat Sofa-Bed Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-GRD-2BX",
  },
  {
    Title: "Gronlid Two-Seat Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-GRD-2X",
  },
  {
    Title: "Gronlid Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-GRD-4",
  },
  {
    Title: "Gronlid Three-Seat Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-GRD-4X",
  },
  {
    Title: "Gronlid Chaise Lounge Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-GRD-5",
  },
  {
    Title: "Gronlid Armrest Covers (One Pair)",
    Vendor: "IKEA",
    "Variant SKU": "IK-GRD-8",
  },
  {
    Title: "Henriksdal Bar Stool Slipcover",
    Vendor: "IKEA",
    "Variant SKU": "IK-HL-BS",
  },
  {
    Title: "Harlanda Footstool Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-HLD-0",
  },
  {
    Title: "Harlanda 1 Seat Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-HLD-1X",
  },
  {
    Title: "Harlanda 2 Seat Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-HLD-2",
  },
  {
    Title: "Harlanda 2 Seat Sofa with Chaise Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-HLD-25",
  },
  {
    Title: "Harlanda Loveseat Sleeper Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-HLD-2BX",
  },
  {
    Title: "Harlanda 2 Seat Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-HLD-2X",
  },
  {
    Title: "Harlanda Chaise Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-HLD-5X",
  },
  {
    Title: "Harlanda Corner Seat Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-HLD-6",
  },
  {
    Title: "Harlanda Armrest Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-HLD-8",
  },
  {
    Title: "Harry Dining Chair Slipcover",
    Vendor: "IKEA",
    "Variant SKU": "IK-HY-DCX",
  },
  {
    Title: "Jattebo 2-seat Modular Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-JTO-2",
  },
  {
    Title: "Jattebo Chaise Longue Module Cover (With Armrest)",
    Vendor: "IKEA",
    "Variant SKU": "IK-JTO-5M",
  },
  {
    Title: "Jattebo Armrest Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-JTO-8",
  },
  {
    Title: "Karlanda Armchair Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-KA-1",
  },
  {
    Title: "Karlanda 3 Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-KA-3",
  },
  {
    Title: "Karlanda Corner Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-KA-362",
  },
  {
    Title: "Karlanda Chaise Lounge Add On Unit Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-KA-5X",
  },
  {
    Title: "Karlanda Armrest Protector Covers",
    Vendor: "IKEA",
    "Variant SKU": "IK-KA-AP",
  },
  {
    Title: "Karlstad Armchair Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-KD-1",
  },
  {
    Title: "Kivik 3.5 Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-KK-4",
  },
  {
    Title: "Kivik Sofa Bed Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-KK-4B",
  },
  {
    Title: "Klippan Footstool Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-KN-0",
  },
  {
    Title: "Kramfors Footstool Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-KS-0",
  },
  {
    Title: "Kramfors 2 Seater (Loveseat) Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-KS-2",
  },
  {
    Title: "Kramfors Corner Unit Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-KS-6",
  },
  {
    Title: "Kramfors Footstool Cushion Cover Only",
    Vendor: "IKEA",
    "Variant SKU": "IK-KS-CC-0",
  },
  {
    Title: "Kramfors 2 Seater Sofa",
    Vendor: "Seat Cushion Cover Only",
    "Variant SKU": "IKEA",
  },
  {
    Title: "Kramfors 3 Seater Sofa",
    Vendor: "Seat Cushion Covers Only",
    "Variant SKU": "IKEA",
  },
  {
    Title: "Kramfors Chaise Lounge Left/Right",
    Vendor: "Seat Cushion Cover Only",
    "Variant SKU": "IKEA",
  },
  {
    Title: "Kramfors Corner Unit",
    Vendor: "Seat Cushion Cover Only",
    "Variant SKU": "IKEA",
  },
  {
    Title: "Kungshamn Footstool Module Slipcover",
    Vendor: "IKEA",
    "Variant SKU": "IK-KUN-0",
  },
  {
    Title: "Kungshamn 1-seat Module Slipcover",
    Vendor: "IKEA",
    "Variant SKU": "IK-KUN-1X",
  },
  {
    Title: "Karlaby Sofa Bed Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-KY-3BX",
  },
  {
    Title: "Lillberg 3 Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-LG-3",
  },
  {
    Title: "Lugnvik Sofa Bed Cover with Chaise",
    Vendor: "IKEA",
    "Variant SKU": "IK-LK-25B",
  },
  {
    Title: "Landskrona 2-seat Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-LSA-2",
  },
  {
    Title: "Landskrona 3-seat (with Chaise) Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-LSA-25",
  },
  {
    Title: "Landskrona 3-seat Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-LSA-3",
  },
  {
    Title: "Lidhult Open End Section With Storage Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-LT-0",
  },
  {
    Title: "Lidhult 1 Seat Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-LT-1X",
  },
  {
    Title: "Lidhult 2 Seat Sofa Bed Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-LT-2BX",
  },
  {
    Title: "Lidhult 2 Seat Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-LT-2X",
  },
  {
    Title: "Lidhult Chaise Lounge Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-LT-5X",
  },
  {
    Title: "Lidhult Corner Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-LT-6",
  },
  {
    Title: "Moheda Bed Compartment Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-MA-CCB",
  },
  {
    Title: "Mysinge Seating Module Chair Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-ME-1",
  },
  {
    Title: "Mysinge 2 Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-ME-2",
  },
  {
    Title: "Mysinge Chaise Lounge Left",
    Vendor: "Right Sofa Cover",
    "Variant SKU": "IKEA",
  },
  {
    Title: "Mysinge Corner Module Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-ME-6",
  },
  {
    Title: "Morabo Armchair Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-MRO-1",
  },
  {
    Title: "Morabo Loveseat Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-MRO-2",
  },
  {
    Title: "Morabo 5-Seat Sectional Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-MRO-263",
  },
  {
    Title: "Norsborg Two-seat Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-NG-2",
  },
  {
    Title: "Norsborg Three-seat Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-NG-3",
  },
  {
    Title: "Norsborg Chaise Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-NG-5",
  },
  {
    Title: "Norsborg Chaise Section Cover Only",
    Vendor: "IKEA",
    "Variant SKU": "IK-NG-5X",
  },
  {
    Title: "Norsborg Armrest Covers (Pair)",
    Vendor: "IKEA",
    "Variant SKU": "IK-NG-8",
  },
  {
    Title: "Nyhamn 3 Seat Sofa Bed Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-NN-3BX",
  },
  {
    Title: "Nils Armchair Slipcover",
    Vendor: "IKEA",
    "Variant SKU": "IK-NS-1",
  },
  {
    Title: "Nockeby 3 Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-NY-4",
  },
  {
    Title: "Parup Loveseat Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-PRP-2",
  },
  {
    Title: "Parup Sofa with Chaise Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-PRP-25",
  },
  {
    Title: "Solsta Pallbo Footstool Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-SA-0",
  },
  {
    Title: "Solsta Olarp Armchair Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-SA-1",
  },
  {
    Title: "Sandbacken Corner Sofabed Covers",
    Vendor: "IKEA",
    "Variant SKU": "IK-SBN-25B",
  },
  {
    Title: "Stromstad 3 Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-SD-3",
  },
  {
    Title: "Stockholm 3 Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-SM-3",
  },
  {
    Title: "Soderhamn One Seat Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-SN-1X",
  },
  {
    Title: "Soderhamn 3 Seat Section Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-SN-2X",
  },
  {
    Title: "Stockholm 2017 Pouffe",
    Vendor: "IKEA",
    "Variant SKU": "IK-STM-0",
  },
  {
    Title: "Stockholm 2017 2-Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-STM-2",
  },
  {
    Title: "Stocksund 2 Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-SU-2",
  },
  {
    Title: "Stocksund Bench Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-SU-9",
  },
  {
    Title: "Sorvallen 2 Seat Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-SVN-3",
  },
  {
    Title: "Sandby Footstool Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-SY-0",
  },
  {
    Title: "Sandby 2 Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-SY-2",
  },
  {
    Title: "Sandby 3 Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-SY-3",
  },
  {
    Title: "Tomelilla 3 Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-TA-3",
  },
  {
    Title: "Tylosand Footstool Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-TD-0",
  },
  {
    Title: "Tylosand 2 Seat Right/Left Arm Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-TD-2M",
  },
  {
    Title: "Tylosand 3 Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-TD-3",
  },
  {
    Title: "Tylosand Chaise Lounge Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-TD-5X",
  },
  {
    Title: "Uppland Ottoman Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-UD-0",
  },
  {
    Title: "Uppland Armchair Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-UD-1",
  },
  {
    Title: "Uppland 2 Seater Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-UD-2",
  },
  {
    Title: "Uppland 4-Seat Corner Sectional Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-UD-242",
  },
  {
    Title: "Uppland Sofa with Chaise Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-UD-25",
  },
  {
    Title: "Vallentuna Back Cushion Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-VA-CC",
  },
  {
    Title: "Vimle Chaise Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-VE-5X",
  },
  {
    Title: "Vinliden 2 Seat Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-VN-2",
  },
  {
    Title: "Vinliden 3 Seat Sofa Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-VN-3",
  },
  {
    Title: "Vaxholm Two-Seat Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-VXM-2X",
  },
  {
    Title: "Vaxholm Chaise Lounge Section Cover",
    Vendor: "IKEA",
    "Variant SKU": "IK-VXM-5X",
  },
  {
    Title: "PB Basic Loveseat Slipcover",
    Vendor: "Pottery Barn",
    "Variant SKU": "PB-BSC-60",
  },
  {
    Title: "PB Basic Sleeper Sofa Slipcover",
    Vendor: "Pottery Barn",
    "Variant SKU": "PB-BSC-82B",
  },
  {
    Title: "Nantucket Armchair Slipcover",
    Vendor: "Rowe",
    "Variant SKU": "RE-NT-39",
  },
  {
    Title: "Roma 3 Seater Sofa Bed Slipcover (12cm)",
    Vendor: "Maisons Du Monde",
    "Variant SKU": "MDM-RA-89B",
  },
  {
    Title: "Boxed Cushion Covers ONLY for Sofa",
    Vendor: "Made to Measure",
    "Variant SKU": "CS3X-CC-SNUG",
  },
  {
    Title: "Boxed Cushion Covers ONLY for Loveseat",
    Vendor: "Made to Measure",
    "Variant SKU": "CS2X-CC-SNUG",
  },
  {
    Title:
      "[PRE-ORDER ONLY] Boxed Seats Long Skirt Round Arm Loveseat Slipcover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS2B-RA-SKIRT",
  },
  {
    Title:
      "[PRE-ORDER ONLY] Boxed Seats Long Skirt Square Arm Loveseat Slipcover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS2B-SA-SKIRT",
  },
  {
    Title: "[PRE-ORDER ONLY] L-seats Long Skirt Round Arm Loveseat Slipcover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS2L-RA-SKIRT",
  },
  {
    Title: "[PRE-ORDER ONLY] Boxed Seats Snug Fit Wedge Arm Loveseat Slipcover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS2B-WA-SHORT",
  },
  {
    Title: "[PRE-ORDER ONLY] L-seats Snug Fit Square Arm Sofa Slipcover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS3L-SA-SHORT",
  },
  {
    Title: "[PRE-ORDER ONLY] L-seats Long Skirt Round Arm Sofa Slipcover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS3L-RA-SKIRT",
  },
  {
    Title: "[PRE-ORDER ONLY] Boxed Seat Long Skirt Square Arm Sofa Slipcover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS3B-SA-SKIRT",
  },
  {
    Title: "[PRE-ORDER ONLY] Boxed Seats Long Skirt Round Arm Sofa Slipcover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS3B-RA-SKIRT",
  },
  {
    Title: "Boxed Seat Left Round Arm Loveseat Section Cover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS2B-SRA-L",
  },
  {
    Title: "Boxed Seat Left Round Arm Chaise Section Cover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS5B-SRA-L",
  },
  {
    Title: "Boxed Seat Right Square Arm Chair Section Cover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS1B-SSA-R",
  },
  {
    Title: "Boxed Seat Right Round Arm Chair Section Cover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS1B-SRA-R",
  },
  {
    Title: "Boxed Seat Right Round Arm Loveseat Section Cover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS2B-SRA-R",
  },
  {
    Title: "L-Seat Right Square Arm Chaise Section Cover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS5L-SSA-R",
  },
  {
    Title: "Boxed Seat Left Square Arm Chair Section Cover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS1B-SSA-L",
  },
  {
    Title: "Boxed Seat Right Round Arm Chaise Section Cover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS5B-SRA-R",
  },
  {
    Title: "Boxed Seat Left Square Arm Loveseat Section Cover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS2B-SSA-L",
  },
  {
    Title: "Boxed Seat Left Round Arm Chair Section Cover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS1B-SRA-L",
  },
  {
    Title: "L-seat Left Square Arm Chaise Section Cover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS5L-SSA-L",
  },
  {
    Title: "Armless Sofa Section Cover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS3X-NA",
  },
  {
    Title: "Corner Square Seat Section Cover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS1-CNR",
  },
  {
    Title: "Boxed Seat Right Square Arm Loveseat Section Cover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS2B-SSA-R",
  },
  {
    Title: "Belgian Track Arm Bench Seat 6ft Sofa Slipcover",
    Vendor: "Restoration Hardware",
    "Variant SKU": "RH-BT-72",
  },
  {
    Title: "Belgian Track Arm Bench Seat 7ft Sofa",
    Vendor: "Restoration Hardware",
    "Variant SKU": "RH-BT-84",
  },
  {
    Title: "[PRE-ORDER ONLY] Boxed Seats Snug Fit Round Arm Loveseat Slipcover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS2B-RA-SHORT",
  },
  {
    Title:
      "[PRE-ORDER ONLY] Boxed Seats Snug Fit Square Arm Loveseat Slipcover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS2B-SA-SHORT",
  },
  {
    Title: "[PRE-ORDER ONLY] L-seats Snug Fit Square Arm Loveseat Slipcover",
    Vendor: "Made to Measure",
    "Variant SKU": "CS2L-SA-SHORT",
  },
  {
    Title: "ceni 39 armchair cover",
    Vendor: "Article",
    "Variant SKU": "AE-CE-39",
  },
  {
    Title: "ottoman large feather pocketed coil cushion cover",
    Vendor: "MUJI",
    "Variant SKU": "MJ-FPC-0",
  },
  {
    Title: "jasper 96 three cushion sofa slipcover",
    Vendor: "Room and Board",
    "Variant SKU": "RB-JR-96-3C",
  },
  {
    Title: "covers for rh capri luxe armless chair",
    Vendor: "Restoration Hardware",
    "Variant SKU": "RH-CI-30X",
  },
  {
    Title: "covers for rh maxwell ottoman",
    Vendor: "Restoration Hardware",
    "Variant SKU": "RH-ML-32F",
  },
  {
    Title: "custom t shaped back cushion cover",
    Vendor: "Made to Measure",
    "Variant SKU": "CC-BK-T",
  },
  {
    Title: "sven sectional sofa cover",
    Vendor: "Article",
    "Variant SKU": "AE-SN-100",
  },
  {
    Title: "Made to Measure",
    Vendor: "Boxed Seat Armless Chair Slipcover",
    "Variant SKU": "CS1X-NA",
  },
];
