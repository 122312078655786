import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Confirmation({showConfirmation, setShowConfirmation, heading, message, handleSure}) {

  const handleClose = () => setShowConfirmation(false);

  return (
    <>
      <Modal show={showConfirmation} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="primary" onClick={handleSure}>Yes, Terminate!</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Confirmation;