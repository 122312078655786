import React from 'react';
import { ScopeProvider } from './Context/ScopeContext';
import TopMenu from './Components/TopMenu';
import AppRouter from './AppRouter';
import './App.css';

import { ApolloProvider } from '@apollo/client';
import client from './ApolloClient';

function App() {
  return (
    <ScopeProvider>
      <ApolloProvider client={client}>
        <div className='pre-potts'>
          <TopMenu />
          <AppRouter />
        </div>
      </ApolloProvider>
    </ScopeProvider>
  );
}

export default App;
