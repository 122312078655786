import React, { useContext } from "react";
import { ScopeContext } from '../Context/ScopeContext';

import Form from 'react-bootstrap/Form';

const SelectOptions = () => {
    
    const { 
        checkboxOptions,
        radioOptions,
        checked,
        setChecked,
        selectedRadio,
        setSelectedRadio
    } = useContext(ScopeContext);

    const handleCheckboxClick = (name) => {
        setChecked(prevState => ({
            ...prevState,
            [name]: !prevState[name]
        }));
    };

    const handleRadioChange = (option) => {
        setSelectedRadio(option);
    };

    return (
        <>
            <div className="radio-group" style={{ marginBottom: '20px' }}>
                {radioOptions.map((option, idx) => (
                    <Form.Check
                        inline
                        key={idx}
                        onChange={() => handleRadioChange(option)}
                        label={option.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
                        name="radioOptions"
                        type='radio'
                        checked={selectedRadio === option}
                        id={`inline-radio-${option}-${idx}`}
                    />
                ))}
            </div>
            <h3>Select Options</h3>
            <Form style={{ marginBottom: '30px' }}>
                <div className="checkbox-group">
                    {checkboxOptions.map((name, idx) => (
                        <Form.Check
                            inline
                            key={idx}
                            onChange={() => handleCheckboxClick(name)}
                            label={name.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
                            name={name}
                            type='checkbox'
                            checked={checked[name]}
                            style={{ marginBottom: '10px' }}
                            id={`inline-checkbox-${name}-${idx}`}
                        />
                    ))}
                </div>
                
            </Form>
        </>
    );
};

export default SelectOptions;
