import React, { createContext, useState } from 'react';

const checkboxOptions = ['exactMatch', 'includeMain', 'verifyVariant', 'fixVariant', 
'updatePrices', 'archived', 'doNotIncludeImages', 'selectFabrics', 'doNotAddMissingFabrics', 'onlyEnglish'];

const radioOptions = ['sync', 'translate', 'disableFabrics'];

export const ScopeContext = createContext();

export const ScopeProvider = ({ children }) => {
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedFabrics, setSelectedFabrics] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);

    const initialCheckboxState = checkboxOptions.reduce((acc, option) => {
        acc[option] = false;
        return acc;
    }, {});

    const [checked, setChecked] = useState(initialCheckboxState);

    const [selectedRadio, setSelectedRadio] = useState(null);

    return (
        <ScopeContext.Provider value={{ 
            selectedBrands, 
            setSelectedBrands, 
            selectedProducts, 
            setSelectedProducts,
            selectedFabrics, 
            setSelectedFabrics,
            selectedCategory,
            setSelectedCategory,
            checkboxOptions,
            radioOptions,
            checked,
            setChecked,
            selectedRadio,
            setSelectedRadio
        }}>
            {children}
        </ScopeContext.Provider>
    );
};
