import React, { useState } from 'react';
import { ListGroup, FormControl, Button } from 'react-bootstrap';

const BrandColumn = ({ brands, onSelectBrand, selectedBrands, setSelectedBrands, handleClearAllBrands }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredBrands = brands.filter(brand => 
    brand.node.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectAllBrands = () => {
    const allBrands = filteredBrands.map(edge => ({
        name: edge.node.name,
        ref: edge.node.ref,
    }));
    
    setSelectedBrands(allBrands);
  }


  return (
    <div>
      <h5>Brands</h5>
      <FormControl 
        placeholder="Search brands..." 
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        style={{ marginBottom: '10px' }}
      />
      <Button variant="outline-dark" style={{ margin: '0 10px 10px 0' }} size="sm" onClick={handleSelectAllBrands}>Select all</Button>
      <Button variant="outline-dark" style={{ margin: '0 0 10px 10px' }} size="sm" onClick={handleClearAllBrands}>Clear all</Button>
      <ListGroup style={{ maxHeight: '500px', overflowY: 'scroll', border: '1px solid #888' }}>
        {filteredBrands.map(brand => (
          <ListGroup.Item 
            key={brand.node.ref} 
            action 
            onClick={() => onSelectBrand({name: brand.node.name, ref: brand.node.ref})}
            active={selectedBrands.some(item => item.ref === brand.node.ref)}
          >
            {brand?.node?.name}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default BrandColumn;
