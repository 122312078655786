import React, { useState } from 'react';
import { ListGroup, FormControl, Button } from 'react-bootstrap';

const FabricsColumn = ({ fabrics, onSelectFabric, selectedFabrics, setSelectedFabrics }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredFabrics = fabrics?.filter(fabric => 
    fabric?.node?.translations.edges[0].node.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!fabrics?.length) {
    return (
      <div>
        <h5>fabrics</h5>
        <p>No fabrics</p>
      </div>
    );
  }

  const handleSelectAllFabrics = () => {
    const allFilteredFabrics = filteredFabrics.map(fabric => ({
        name: fabric.node.translations.edges[0].node.name,
        ref: fabric.node.reference,
    }));
    setSelectedFabrics(allFilteredFabrics);
  }

  const handleClearAllFabrics = () => {
    setSelectedFabrics([])
  }

  return (
    <div>
      <h5>Fabrics</h5>
      <FormControl 
        placeholder="Search fabrics..." 
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: '10px' }}
        value={searchTerm}
      />
      <Button variant="outline-dark" style={{ margin: '0 10px 10px 0' }} size="sm" onClick={handleSelectAllFabrics}>Select all</Button>
      <Button variant="outline-dark" style={{ margin: '0 0 10px 10px' }} size="sm" onClick={handleClearAllFabrics}>Clear all</Button>
      <ListGroup style={{ maxHeight: '500px', overflowY: 'scroll', border: '1px solid #888' }}>
        {filteredFabrics.map((fabric, index) => (
          <ListGroup.Item 
            key={`${fabric?.node?.reference}-${index}`} 
            action 
            onClick={() => onSelectFabric({name: fabric?.node?.translations.edges[0].node.name, ref: fabric?.node?.reference})}
            active={selectedFabrics.some(item => fabric?.node?.reference === item.ref)}
          >
            {fabric?.node?.translations.edges[0].node.name}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default FabricsColumn;
