import React, { useContext, useState } from 'react';
import { ScopeContext } from '../Context/ScopeContext';
import { Container, Button } from 'react-bootstrap';
import FabricsScope from '../Components/FabricsScope';
import ProductScope from '../Components/ProductScope';
import Notification from '../Components/Notifications';
import SelectOptions from '../Components/SelectOptions'
import { getCookieValue } from "../Context/utils";
import Form from 'react-bootstrap/Form';

const ScopingPage = () => {

    const gsheetLoad = (e) => {
        const session = getCookieValue('sessionid');
        fetch(`https://pre-potts-middleware.comfort-works.com/getsheetid/?session=${getCookieValue('sessionid')}`)
        .then(response => response.json())
        .then(data => {
          if (data.status) {
            setGsheetId(data.id);
            return data.id;
          }
        })
        .catch((err) => {
          console.error(err.message);
          return '';
        });
        return '';
    }

    const [isSyncing, setSyncing] = useState(false);

    const [showNotification, setShowNotification] = useState(false)
    const [message, setMessage] = useState('')
    const [type, setType] = useState('')
    const [gsheetId, setGsheetId] = useState(gsheetLoad)

    const { 
        selectedProducts, 
        selectedFabrics, 
        checked,
        selectedRadio
    } = useContext(ScopeContext);

    const isButtonDisabled = selectedProducts.length ? false : true;

    const getCheckboxValue = (name) => {
        return checked[name] ? 1 : 0;
    }


    const getRadioValues = () => {
        let initialValues = {
            "sync": 0,
            "translate": 0,
            "disableProducts": 0,
            "disableFabrics": 0
        }
        if(selectedRadio) initialValues[selectedRadio] = 1
        return initialValues;
    }

    const handleSync = () => {
        const fabricsRefs = selectedFabrics.map(fabric => fabric.ref);
        const productsRefs = selectedProducts.map(product => product.ref);
        setSyncing(true)

        let radioValues = getRadioValues()
        
        const postData = {
            "session": getCookieValue('sessionid'),
            "products": productsRefs,
            "fabrics": fabricsRefs,
            "exactMatch": getCheckboxValue('exactMatch'),
            "includeMain": getCheckboxValue('includeMain'),
            "verifyVariant": getCheckboxValue('verifyVariant'),
            "fixVariant": getCheckboxValue('fixVariant'),
            "updatePrices": getCheckboxValue('updatePrices'),
            "archived": getCheckboxValue('archived'),
            "selectFabrics": getCheckboxValue('selectFabrics'),
            "doNotIncludeImages": getCheckboxValue('doNotIncludeImages'),
            "doNotAddMissingFabrics": getCheckboxValue('doNotAddMissingFabrics'),
            "disableProducts": getCheckboxValue('disableProducts'),
            "translate": getCheckboxValue('disableProducts'),
            "onlyEnglish": getCheckboxValue('onlyEnglish'),
            ...radioValues
        }

        console.log(postData)

        const requestOptions = {
            method: 'POST',
            cache: "no-cache",
            redirect: "follow",
            headers: { 'Accept': '*/*', 'Content-Type': 'application/json', 'Accept-Encoding': 'gzip,deflate,br', 'Connection': 'keep-alive', 'User-Agent': 'POTTSfrontend'},
            body: JSON.stringify(postData)
        };
        fetch('https://pre-potts-middleware.comfort-works.com/literature', requestOptions)
            .then(response => {
                console.log(response);
                if (!response.ok) {
                    throw new Error('Response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data)
                setSyncing(false)
                setShowNotification(true)
                if(data.status){
                    setMessage(data?.message)
                    setType('success')
                }else{
                    setMessage(data?.error)
                    setType('danger')
                }
            })
            .catch((err) => {
                console.log(err.message);
                setMessage(err?.message)
                setShowNotification(true)
                setType('danger')
                setSyncing(false)
             });
        
    }



    const gsheetUpdate = (e) => {
        if (e.target.value.length < 1) {
          return;
        }
        setGsheetId(e.target.value);
        fetch(`https://pre-potts-middleware.comfort-works.com/putsheetid/?session=${getCookieValue('sessionid')}&sheetid=${e.target.value}`)
        .then(response => response.json())
        .then(data => {
          if (data.status) {
          }
        })
        .catch((err) => {
          console.error(err.message);
        });
    }


    const forego = (e) => {
        e.preventDefault()
    }


    return (
        <>
            <Container style={{ margin: '40px auto', position: 'relative' }}>
               <Form.Control type="text" placeholder="Google sheet ID" value={gsheetId} onChange={gsheetUpdate} />
            </Container>
            <Container style={{ margin: '40px auto', position: 'relative' }}>
                <SelectOptions />
                <ProductScope />
                <FabricsScope />
                <div className='syncContainer'>
                    <Button disabled={isButtonDisabled || isSyncing} onClick={handleSync} className='syncButton'>
                        {isSyncing? 'Syncing...' : 'Sync'}
                    </Button>
                </div>
            </Container>
            <Notification 
                showNotification={showNotification} 
                setShowNotification={setShowNotification} 
                type={type} message={message} 
            />
        </>
    );
};

export default ScopingPage;
