import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import { getCookieValue } from "../Context/utils";
import { JSONTree } from 'react-json-tree';


const Reports = () => {
  const [session, setSession] = useState(null);
  const queryParameters = new URLSearchParams(window.location.search);
  const queue_id = queryParameters.get("queue");
  const [logData, setLogData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const session = getCookieValue('sessionid');
    setSession(session);

    if (session) {
      setIsLoading(true);
      /*fetch(`https://pre-potts-obtru66mgq-uc.a.run.app/logs/?session=${session}&queue=${queue_id}`)*/
      fetch(`https://pre-potts-middleware.comfort-works.com/logs/?session=${session}&queue=${queue_id}`)
        .then(response => response.json())
        .then(data => {
          if (data.status) {
            const processedLogs = data.log.map(log => {
              let eventString = log.event;
              const prefix = "Working on: ";
              if (eventString.startsWith(prefix)) {
                eventString = eventString.substring(prefix.length);
              }
              try {
                log.event = JSON.parse(eventString);
              } catch (e) {
                log.event = eventString;
              }
              return log;
            });
            setLogData(processedLogs);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err.message);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [queue_id]);


    const renderLogEvent = (event) => {
        if (typeof event === 'object') {
            return <JSONTree data={event} invertTheme={false} shouldExpandNode={() => true} />;
        } else {
            return <pre>{event}</pre>;
        }
    };

  if (!session) {
    return <h4 style={{ margin: '40px auto', textAlign: 'center' }}>No session found</h4>;
  }

  if (isLoading) {
    return <img src="./loading.gif" alt="Loading..." style={{ display: 'block', margin: '40px auto' }} />;
  }

  return (
    <>
      <Container style={{ margin: '40px auto' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Log Details</h2>
        
        {logData.length > 0 ? logData.map((log, index) => (
            <Card key={index} style={{ marginBottom: '5px' }}>
                <Card.Body>
                    <Row>
                        <Col xs={12} sm={9} md={10}>
                            <strong>Log Event:</strong>
                            {renderLogEvent(log.event)}
                        </Col>
                        <Col xs={12} sm={3} md={2}>Event Subject: {log.eventSubject ? 'Yes' : 'No'}</Col>
                    </Row>
                </Card.Body>
            </Card>
        )) : <h4 style={{ textAlign: 'center' }}>No Log data.</h4>}
      </Container>
    </>
  );
};

export default Reports;
