import React, { useContext, useEffect, useState } from 'react';
import { ScopeContext } from '../Context/ScopeContext';
import { Row, Col } from 'react-bootstrap';
import BrandColumn from './BrandColumn';
import ProductColumn from './ProductColumn';
import { useQuery, gql } from '@apollo/client';
import { productData } from '../Data/productData';

const BRANDS_DATA = gql`
query brands {
    brands{
      edges{
        node{
            name
            ref
            productSet{
                edges{
                    node{
                        id
                        name
                        reference
                        translations(lang: "en"){
                            edges{
                                node{
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
      }
    }
}
`;

const PRODUCTS_DATA = gql`
query products{
    products(brand_Ref: "") {
      edges {
        node {
          id
          reference
          status
          brand {
            name
          }
          translatedName
        }
      }
      totalCount
    }
}
`;


const ProductScope = () => {

    const { 
        selectedBrands, 
        setSelectedBrands, 
        selectedProducts, 
        setSelectedProducts,
    } = useContext(ScopeContext);

    const { loading, error, data } = useQuery(BRANDS_DATA);

    const { loading: loadingProducts, error: errorProducts, data: dataProducts } = useQuery(PRODUCTS_DATA);

    const [combinedDataProducts, setCombinedDataProducts] = useState(null);

    useEffect(() => {
        if (dataProducts && productData) {
            // Generate combined data and set it to state
            const newDataProducts = appendMissingProducts(dataProducts, productData);
            setCombinedDataProducts(newDataProducts);
        }
    }, [dataProducts]);

    if (loading || loadingProducts) return <div><img src='./loading.gif' alt='loading products...' /><strong>Loading Products...</strong></div>;
    if (error || errorProducts) return <p>Error loading products :( </p>;

    const appendMissingProducts = (dataProducts, productData) => {
        // Convert productData into the format of dataProducts
        const formattedProductData = productData.map(product => ({
            node: {
                reference: product["Variant SKU"],
                status: "Not sure", 
                brand: {
                    name: product["Vendor"]
                },
                translatedName: product["Title"]
            }
        }));
    
        // Create a copy of dataProducts to modify
        const newDataProducts = {
            ...dataProducts,
            products: {
                ...dataProducts.products,
                edges: [...dataProducts.products.edges]
            }
        };
    
        // Append missing products
        formattedProductData.forEach(product => {
            if (!newDataProducts.products.edges.some(edge => edge.node.reference === product.node.reference)) {
                newDataProducts.products.edges.push(product);
            }
        });
        console.log(newDataProducts)
        return newDataProducts;
    };
    
    

    const handleSelectBrand = (brand) => {
        if(selectedBrands.some(item => item.ref === brand.ref)){
            const filteredBrands = selectedBrands.filter(item => item.ref !== brand.ref)
            setSelectedBrands(filteredBrands)
        }else{
            setSelectedBrands([
                ...selectedBrands,
                brand
            ])
        }
    };
  

    // Handle product selection
    const handleSelectProduct = (product) => {
        if(selectedProducts.some(item => item.ref === product.ref)){
            const filteredProducts = selectedProducts.filter(item => item.ref !== product.ref)
            setSelectedProducts(filteredProducts)
        }else{
            setSelectedProducts([
                ...selectedProducts,
                product
            ])
        }
    };

    function getProductSetBySelectedBrands() {
        // Check if the data and brandName are valid
        if (!selectedBrands?.length) return [];

        const brandsArray = data.brands.edges
        let productsArray = []
        console.log(combinedDataProducts.products.edges.length)
        for (let i = 0; i < brandsArray.length; i++) {
            for(let j = 0; j < selectedBrands.length ; j++){
                if (brandsArray[i].node.name === selectedBrands[j].name) {
                    //productsArray = productsArray.concat(brandsArray[i].node.productSet.edges);
                    productsArray = productsArray.concat(combinedDataProducts.products.edges.filter(product => (product.node.brand?.name === selectedBrands[j].name)));
                }
            }
        }
        console.log(productsArray.length)
        const uniqueProducts = productsArray.filter((obj, index) => {
            return index === productsArray.findIndex(o => obj.node.reference === o.node.reference);
        });
        console.log(uniqueProducts.length)
        return uniqueProducts;
    }

    const handleClearAllBrands = () => {
        setSelectedBrands([]);
        setSelectedProducts([]);
    }

    
  

    return (
        <Row>
            <Col md={3}>
            <BrandColumn 
                brands={data?.brands?.edges || null} 
                onSelectBrand={handleSelectBrand} 
                selectedBrands={selectedBrands} 
                setSelectedBrands={setSelectedBrands}
                handleClearAllBrands={handleClearAllBrands}
            />
            </Col>
            
            <Col md={4}> 
            <ProductColumn 
                products={selectedBrands.length ? getProductSetBySelectedBrands() : []} 
                onSelectProduct={handleSelectProduct} 
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
            />
            </Col>

            <Col md={4}>
                <h5>Selected Products</h5>
                <div className='selectedList'>
                {
                    selectedProducts.length ? selectedProducts.map(product => (
                        <div className='selectedProduct' key={product.ref}>
                            {product.name}
                        </div>
                    )) : <>No Product selected</>
                }
                </div>
            </Col>
            
        </Row>
    );
};

export default ProductScope;
