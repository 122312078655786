import React, { useState } from 'react';
import { ListGroup, FormControl, Button } from 'react-bootstrap';

const ProductColumn = ({ products, onSelectProduct, selectedProducts, setSelectedProducts }) => {
  const [searchTerm, setSearchTerm] = useState('');

  let filteredProducts = [];
  let searchTerms = searchTerm.split(/,\s*/);
  if (searchTerms.length > 0) {
    filteredProducts = [];
    searchTerms.forEach((sTerm) => {
      filteredProducts?.push(...products?.filter(product => 
        `${product?.node?.translatedName} (${product?.node?.status})`.toLowerCase().includes(sTerm.toLowerCase())
      ));
    });
    console.log(filteredProducts);
  } else {
    filteredProducts = products?.filter(product => 
      `${product?.node?.translatedName} (${product?.node?.status})`.toLowerCase().includes(searchTerm.toLowerCase())
    );  
  }

  if (!products?.length) {
    return (
      <div>
        <h5>Products</h5>
        <p>No products</p>
      </div>
    );
  }

  const handleSelectAllProducts = () => {
    const allProducts = filteredProducts.map(product => ({
        name: product.node.translatedName,
        ref: product.node.reference,
    }));

    setSelectedProducts(allProducts);
  }
  const handleClearAllProducts = () => {
    setSelectedProducts([]);
  }

  return (
    <div>
      <h5>Products</h5>
      <FormControl 
        placeholder="Search products..." 
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: '10px' }}
        value={searchTerm}
      />
      <Button variant="outline-dark" style={{ margin: '0 10px 10px 0' }} size="sm" onClick={handleSelectAllProducts}>Select all</Button>
      <Button variant="outline-dark" style={{ margin: '0 0 10px 10px' }} size="sm" onClick={handleClearAllProducts}>Clear all</Button>

      <ListGroup style={{ maxHeight: '500px', overflowY: 'scroll', border: '1px solid #888' }}>
        {filteredProducts.map((product, index) => (
          <ListGroup.Item 
            key={`${product?.node?.reference}-${index}`} 
            action 
            onClick={() => onSelectProduct({name: product?.node?.translatedName, ref: product?.node?.reference})}
            active={selectedProducts.some(item => product?.node?.reference === item.ref)}
          >
            {product?.node?.translatedName} ({product?.node?.status})
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default ProductColumn;
