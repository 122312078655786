import React from "react";
import { Routes, Route } from "react-router-dom";
import ScopingPage from "./Pages/ScopingPage";
import ProgressChecker from "./Pages/ProgressChecker";
import Reports from './Pages/Reports';

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ScopingPage />} />
      <Route path="/progress-checker" element={<ProgressChecker />} />
      <Route path="/reports" element={<Reports />} />
    </Routes>
  );
};

export default AppRouter;
